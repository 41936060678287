// re-export types
export * from './discount'
export * from './events'
export * from './marketingPopup'
export * from './abtest'
export * from './performance'

// product options
export const SIZE_OPTION_SIGN = ['size']
export const COLOR_OPTION_SIGN = ['color', 'colour']
export const STYLE_OPTION_SIGN = ['style', 'type']
export const OPTION_TYPE_STYLE = 'style'
export const OPTION_TYPE_COLOR = 'color'
export const OPTION_TYPE_SIZE = 'size'
export const OPTION_TYPE_UNKNOW = 'style'
export const OPTION_TYPE_SHIPPED_FROM = 'Produced In And Shipped From'
export const OPTION_NAME_TYPE = 'Type'
export const NUMBER_OF_OPTIONS_TO_SHOW_DROPDOWN = 12
export const HIDDEN_ATTRIBUTE_PREFIX = '_'
export const LINE_ITEM_PREVIEW_IMAGE_ATTRIBUTE = '_customily-preview'
export const LINE_ITEM_PRODUCTION_IMAGE_ATTRIBUTE = '_customily-production-url'
export const LINE_ITEM_CUSTOMIZATION_URL_ATTRIBUTE =
  '_customily-customization-url'
export const PRODUCT_AVERAGE_REVIEW_META_FIELD_KEY = 'badge'
export const PRODUCT_REVIEW_META_FIELD_NAMESPACE = 'judgeme'
export const PRODUCT_ID_PREFIX = 'gid://shopify/Product'
export const CUSTOMER_ID_PREFIX = 'gid://shopify/Customer'
export const PRODUCT_MEDIA_VIDEO = 'VIDEO'
export const PRODUCT_SOURCE_FORMAT_MP4 = 'mp4'

// collections
export const COLLECTION_TAGS_META_FIELD_NAMESPACE = 'custom'
export const COLLECTION_TAGS_META_FILED_KEY = 'tags'
export const COLLECTION_COLLECTION_MKWC_META_FILED_KEY = 'collection_mkwc'
export const COLLECTION_RESTRICTED_BY_PRODUCT_TYPE = 'product_type'
export const COLLECTION_AFTER_QUERY_STRING = 'after'
export const COLLECTION_BEFORE_QUERY_STRING = 'before'

// general
export const LOG_LEVEL_QUERY_STRING = 'logLevel'
export const SSR_CONTEXT_APPEND_TO_HEAD_IMAGE_PRELOAD_LINKS =
  'sib_append_to_head_image_preload_links'
export const SSR_CONTEXT_APPEND_TO_HEAD_FONT_PRELOAD_LINKS =
  'sib_append_to_head_font_preload_links'
export const SSR_CONTEXT_APPEND_TO_HEAD_META_TAGS =
  'sib_append_to_head_meta_tags'
export const SSR_CONTEXT_APPEND_TO_HEAD_IMAGE_META_TAGS =
  'sib_append_to_head_image_meta_tags'
export const SSR_CONTEXT_APPEND_TO_HEAD_JSON_LD = 'sib_append_to_head_json_ld'
export const SSR_CONTEXT_APPEND_TO_HEAD_CANONICAL_LINK =
  'sib_append_to_head_canonical_link'
export const SHOP_DOMAIN = import.meta.env.VITE_SHOPIFY_DOMAIN as string
export const SHOP_CHECKOUT_DOMAIN = import.meta.env
  .VITE_SHOPIFY_CHECKOUT_DOMAIN as string
export const PLATFORM = 'shopify'
export const SHOP_ID = import.meta.env.VITE_SHOPIFY_ID as string
export const SEARCH_RESULT_PAGE_TITLE = 'Search results'
export const PAGE_NOT_FOUND_TITLE = 'Page not found'

export const PAGE_NOT_FOUND_ROUTER_NAME = 'notFound'

export const US_COUNTRY_CODE = 'US'
export const CA_COUNTRY_CODE = 'CA'
export const VN_COUNTRY_CODE = 'VN'
export const CURRENCY = 'USD'
export const GOOGLE_BUSINESS_VERTICAL = 'retail'

export const PAGE_TITLE = 'Personalized Gifts, Customized Decor'
export const PAGE_DESCRIPTION =
  'The Most Creative and Unique Personalized Gifts for everyone'

export const SSR_LOAD_TYPE = 'ssr'
export const CSR_LOAD_TYPE = 'csr'

export const PRODUCT_ID_TRACKING_BASE_ONE_PRODUCT_VARIANT_SKU =
  'productVariantSku'

// search type
export const SIB_SEARCH = 'sib-search'
export const SEARCHANISE = 'searchanise'

// search query
export const SEARCHANISE_TAG_SPLITER = '[:ATTR:]'
export const SEARCHANISE_IMAGE_SIZE_VARIABLE = '_large'
export const SEARCH_QUERY_STRING = 'query'
export const SEARCH_SHORT_QUERY_STRING = 'q'
export const SORT_BY_QUERY_STRING = 'sort_by'
export const SORT_ORDER_QUERY_STRING = 'sort_order'
export const SEARCH_RESTRICT_BY_QUERY_STRING_PREFIX = 'rb_'
export const SEARCH_RESTRICT_BY_PARAM_PREFIX = 'restrictBy'
export const SEARCH_GROUP_COLLECTION_BY_PARAM_PREFIX = 'group_collections'
export const SEARCH_RESTRICT_BY_COLLECTIONS_QUERY_STRING = 'rb_collections'
export const SEARCH_RESTRICT_BY_PRODUCT_TYPE_QUERY_STRING = 'rb_product_type'
export const SEARCH_RESTRICT_BY_TAG_QUERY_STRING = 'rb_tags'
export const PAGE_QUERY_STRING = 'page'

// query string
export const QUERY_STRING_TAG_DISCOUNT_CODE = 'utm_tag_discount_code'
export const QUERY_STRING_GOOGLE_CLICK_ID = 'gclid'
export const QUERY_STRING_GOOGLE_DISCOUNT = 'utm_gg_discount'
export const QUERY_STRING_DISCOUNT = 'utm_discount_code'
export const QUERY_STRING_CAMPAIGN_DISCOUNT = 'utm_campaign_discount'
export const QUERY_STRING_PRODUCT_IDS = 'utm_product_ids'
export const QUERY_STRING_PRODUCT_HANDLE = 'utm_product_handle'
export const QUERY_STRING_UTM_COLLECTION_HANDLE = 'utm_collection_handle'
export const QUERY_STRING_UTM_TYPE_COLLECTION = 'utm_type_collection'
export const QUERY_STRING_REDIRECT_PRODUCT_HANDLE =
  'utm_redirect_product_handle'
export const QUERY_STRING_REDIRECT_PRODUCT_ID = 'utm_redirect_product_id'
export const QUERY_STRING_NO_CACHE = 'no_cache'
export const QUERY_STRING_GP_CUSTOM_DISABLE = 'gp_custom_disable'
export const QUERY_STRING_UTM_FB_FAKE_PURCHASE = 'utm_fb_sale'
export const QUERY_STRING_UTM_CAMPAIGN = 'utm_campaign'
export const QUERY_STRING_FBCLID = 'fbclid'
export const QUERY_STRING_PROMOTION_MESSAGE = 'utm_promotion_message'
export const QUERY_STRING_CUSTOMILY_CUSTOMIZATION_ID = 'customizationId'
export const QUERY_STRING_GREETING_CARD = 'greeting_card'
export const QUERY_STRING_KOL_CAMPAIGN_CODE = 'kol_campaign_code'

// cache keys
export const CACHE_KEY_PREFIX = 'SIB_'
export const CACHE_KEY_USER_LOCATION = 'core_userLocation'
export const CACHE_KEY_WISH_LIST_ITEMS = 'product_wishListItems'
export const CACHE_KEY_RECENTLY_VIEWED_ITEMS = 'product_recentlyViewedItems'
export const CACHE_KEY_RECENTLY_VIEWED_ITEMS_SKU_FROM_HANDLE =
  'product_recentlyViewedItemsSKUFromHandle'
export const CACHE_KEY_CHECKOUT_ID = 'checkout_checkoutId'
export const CACHE_KEY_CART_ID = 'cart_cartId'
export const CACHE_KEY_SEARCH_RECENTLY_SEARCHES = 'search_recentlySearches'
export const CACHE_KEY_CAMPAIGN_DISCOUNT_CODE = 'discount_campaignDiscountCode'
export const CACHE_KEY_DISCOUNT_CODE = 'discount_discountCode'
export const CACHE_KEY_TAG_DISCOUNT_CODE = 'discount_tagDiscountCode'
export const CACHE_KEY_DISCOUNT_COUNT_DOWN_TIME = 'discount_countDownTime'
export const CACHE_KEY_GOOGLE_DISCOUNT_CODE = 'discount_googleDiscountCode'
export const CACHE_KEY_GOOGLE_CLICK_ID = 'google_gclid'
export const CACHE_KEY_COLLECTION_BY_HANDLE = 'collection_handle'
export const CACHE_KEY_REDIRECT_LINK = 'redirect_link'
export const CACHE_KEY_AB_TEST = 'abtest'
export const CACHE_KEY_UTMS = 'utms'
export const CACHE_HIDE_POPUP_AND_SHOW_TRRIGER_BUTTON_WITHIN_DAY_NUMBER =
  'popup_hidePopupAndShowTriggerButtonWithinDayNunber'
export const CACHE_HIDE_POPUP_AND_TRIGGER_BUTTON_FOR_USER_SUBMITTED_THE_FORM =
  'popup_hidePopupAndTriggerButtonForUserSubmittedTheForm_'
export const CACHE_KEY_CUSTOMER_ACCESS_TOKEN = 'customer_accessToken'
export const CACHE_KEY_CUSTOMILY_PERSONALIZED = 'customily_personalized_ids'

// cookie
export const COOKIE_KEY_PREFIX = 'sib_'
export const COOKIE_KEY_ATTRIBUTE_UTM_PREFIX = '_first_'
export const COOKIE_KEY_RUDDER_USER_ID = 'sib_rudder_userId'
export const COOKIE_KEY_KLAVIYO_EMAIL = 'sib_klaviyo_email'
export const COOKIE_VALUE_PRODUCT_PAGE_OLD_UTM_CAMPAIGN = 'page_old'
export const COOKIE_VALUE_PRODUCT_PAGE_NEW_UTM_CAMPAIGN = 'page_new'

// first page key tracking
export const FIRST_PAGE_KEY = '_firstPage'

// custom attribute product
export const CUSTOM_IMAGE_LINK_PREFIX = '_sib-image-link'
export const CUSTOM_AUDIO_LINK_PREFIX = '_sib-audio-link'
export const CUSTOM_SONG_LINK_PREFIX = '_sib-song-link'
export const CUSTOM_VIDEO_LINK_PREFIX = '_sib-video-link'
export const CUSTOM_COLOR_PICKER_PREFIX = '_sib-color-picker'
export const FACE_SWAP_IMAGE_PREFIX = '_sib-face-swap-image'
export const NO_PREVIEW_PERSONALIZATION = '_no-preview-personalization'
export const SHIPPING_CALCULATION = '_shipping-calculation'
export const SELECTED_VARIANT_TITLE_ATTRIBUTE_KEY = '_selected-variant-title'

export const DONATE_PRICE_ATTRIBUTE_KEY = '_donate-price'
export const KOL_CAMPAIGN_CODE_ATTRIBUTE_KEY = '_kol_campaign_code'
export const TOTAL_AMOUNT_PERCENTAGE_ATTRIBUTE_KEY = '_total_amount_percentage'
export const DONATE_AMOUNT_PERCENTAGE_ATTRIBUTE_KEY =
  '_donate_amount_percentage'
export const KOL_CAMPAIGN_NAME_ATTRIBUTE_KEY = '_kol_campaign_name'
export const PHOTO_ALBUM_ATTRIBUTE_KEY = '_photo-album'
export const EXPRESS_SHIPPING_ATTRIBUTE_KEY = '_express_shipping'
export const EXPRESS_SHIPPING_PRICE_ATTRIBUTE_KEY = '_express_shipping_price'
export const SHIPPING_FEE_PRICE_ATTRIBUTE_KEY = '_shipping-fee-price'
export const MADEMINE_ATTRIBUTE_KEY = '_mademine-item-key'
export const MADEMINE_PREVIEW_ATTRIBUTE_KEY = 'preview'
export const PRODUCT_COMBO_HANDLE_ATTRIBUTE_KEY = '_product-combo-handle'
export const PRODUCT_COMBO_SETTING_ATTRIBUTE_KEY = '_product-combo-setting'
export const UPSELL_SAME_DESIGN_WITH_PRODUCT_TITLE =
  '_upsell-same-desin-with-product-title'
export const UPSELL_SAME_DESIGN_WITH_LINE_ITEM_ID =
  '_upsell-same-desin-with-line-item-id'
export const HANDLE_PRODUCT_UPSELL_WITH_GREETING_CARD =
  '_handle-product-upsell-with-greeting-card'

//product tag
export const PRODUCT_TAG_CUSTOM_PHOTO_ALBUM = 'custom_photo_album'
export const PRODUCT_TAG_CUSTOMILY_OPTIONSET_ONLY = 'customily_optionset_only'
export const PRODUCT_TAG_SHIP_FROM_US = 'ship_from_us'
export const PRODUCT_TAG_EXPRESS_SHIPPING_PREFIX = 'express_shipping'
export const PRODUCT_TAG_EXPRESS_SHIPPING_CHINA = 'express_shipping_china'
export const PRODUCT_TAG_PROMOTION_NO = 'promotion-no'
export const PRODUCT_TAG_TMR1 = 'TMR1'
export const PRODUCT_TAG_UI_LANDING = 'ui-landing-product'
export const PRODUCT_TAG_IGN_PACK_DISCOUNT = 'ign-pack-discount'
export const PRODUCT_TAG_HIDDEN_PREVIEW_NOTE = 'hidden_preview_note'
export const PRODUCT_TAG_CUSTOM_CROSS_WORDS = 'custom_cross_words'
export const PRODUCT_TAG_CUSTOM_PHOTO_WORD = 'custom_photo_word'
export const PRODUCT_TAG_SELL_ONLY_US = 'only_US'
export const PRODUCT_TAG_APPLY_FAKE_PRICE_BF9_BUT_NO_DISCOUNT = 'provipluxury'
export const PRODUCT_TAG_DISCOUNT_DEFAULT = 'discount_default'
export const PRODUCT_TAG_CUSTOMILY_VARIANTS_OPTION_PREFIX =
  'customily-variants-option'

// product type
export const PRODUCT_TYPE_GREETING_CARD = 'Greeting Card'
export const PRODUCT_TYPE_YOUR_DESIGN = 'YourDesign Product'
export const PRODUCT_TYPE_DIGITAL_PRODUCT = 'Digital Product'
export const PRODUCT_TYPE_QR_GREETING_CARD = 'QR Greeting Card'
export const PRODUCT_TYPE_DIGITAL_COMIC_BOOK = 'Digital Comic Book'
export const PRODUCT_TYPE_DIGITAL_PHOTO_BOOK = 'Digital Photo Book'
export const PRODUCT_TYPE_ACCENT_MUG = 'Accent Mug'

export const PACK_TYPE = '_pack-type'
export const PRODUCT_PACK = '_product-pack'
export const PACK_OPTIONS = 'Pack'
export const TYPE_OPTIONS = 'Type'
export const SIZE_OPTIONS = 'Size'

// event name
export const EVENT_ADD_TO_CART = 'event_addToCart'
export const EVENT_BUY_NOW = 'event_buyNow'
export const EVENT_APP_MOUNTED = 'event_appMounted'
export const EVENT_APP_PROGRESS_START = 'event_appProgressStart'
export const EVENT_APP_PROGRESS_FINISH = 'event_appProgressFinish'
export const EVENT_PRODUCT_UPDATE_MAIN_SLIDER = 'event_productUpdateMainSlider'
export const EVENT_PRODUCT_SHOW_PREVIEW = 'event_productShowPreview'
export const EVENT_SCROLL_TO_REVIEW_SECTION = 'event_scrollToReviewSection'
export const EVENT_CUSTOMILY_START_LOADING = 'event_customilyStartLoading'
export const EVENT_CUSTOMILY_DONE_LOADING = 'event_customilyDoneLoading'
export const EVENT_TOGGLE_CART_ITEM_PERSONALIZATION =
  'event_toggleCartItemPersonalization'
export const EVENT_CUSTOM_UPLOAD_IMAGE = 'event_customUploadImage'
export const EVENT_CHANGE_COLOR_QR_CODE = 'event_changeColorQrCode'
export const EVENT_CHANGE_AUDIO_SOUND_WAVE = 'event_changeAudioSoundWave'
export const EVENT_CHANGE_SPOTIFY_SOUND_WAVE = 'event_changeSpotifySoundWave'
export const EVENT_OPEN_POPUP_SIGN_IN = 'event_openPopupSignIn'
export const EVENT_SAVE_DISCOUNT_CODE_TO_CACHE = 'event_saveDiscountCodeToCache'
export const EVENT_ERROR_FILL_OUT_ALL_PAGE_PHOTO_ALBUM =
  'event_errorFillOutAllPagePhotoAlbum'
export const EVENT_CHECKING_URL = 'event_checkingUrl'
export const EVENT_ADD_TO_CART_DIGITAL_CONTENT_PRODUCT_FALSE =
  'event_addToCartDigitalContentProductFalse'
export const EVENT_REMOVE_SELECTED_FILTER = 'event_removeSelectedFilter'
export const EVENT_CHANGE_SELECTED_FILTER = 'event_changeSelectedFilter'
export const EVENT_REMOVE_ALL_SELECTED_FILTER = 'event_removeAllSelectedFilter'
export const EVENT_OPEN_FILTER_POPUP_BASE_ON_ATTRIBUTE =
  'event_openFilterPopupBaseOnAttribute'
export const EVENT_CLICK_PRODUCT_FORM_REVIEW_BUTTON =
  'event_ClickProductFormReviewButton'
export const EVENT_SAVE_PRODUCT_WISH_LIST = 'event_saveProductWishlist'
export const EVENT_UPDATE_QUANTITY = 'event_updateQuantity'
export const EVENT_SHOW_PRODUCT_OPTIONS_POPUP = 'event_showProductOptionsPopup'
export const EVENT_SHOW_CART_POPUP = 'event_showCartPopup'
export const EVENT_CLOSE_CART_POPUP = 'event_closeCartPopup'
export const EVENT_ADD_TO_CART_COMBO_STATUS = 'event_addToCartComboStatus'
export const EVENT_CLICK_ADD_TO_CART_ON_POPUP_PREVIEW =
  'event_clickAddToCartInPreviewPopup'
export const EVENT_UPDATE_GIFT_BOX_EXTRA = 'event_updateGiftBoxExtra'
export const EVENT_UPDATE_GREETING_CARD_EXTRA = 'event_updateGreetingCardExtra'
export const EVENT_SHOW_AVERAGE_START_PRE_BAGDE =
  'event_showAverageStarPreBagde'

// some element size
export const HEADER_HEIGHT = 65

// window properties
export const CUSTOMILY_MODULE_EXPORT = 'customily.shopify.script'
export const CUSTOMILY_SHOPIFY = 'customilyShopify'
export const CUSTOMILY_OPTION_LOADED_EVENT = 'customily-options-loaded'
export const CUSTOMILY_OPTION_CHANGED_EVENT = 'customily-options-changed'
export const CUSTOMILY_IMAGE_UPLOADED_EVENT = 'customily-image-uploaded'
export const CUSTOMILY_LOADERS_SHOWN_EVENT = 'customily-loaders-shown'
export const CUSTOMILY_LOADERS_HIDDEN_EVENT = 'customily-loaders-hidden'

// element id, classes
export const PRODUCT_MAIN_SLIDER_PREVIEW_SLIDE_ID = 'product__preview-slide'
export const PRODUCT_MAIN_SLIDER_PREVIEW_SLIDE_ACTIVE_CLASS = 'active'
export const CUSTOMILY_PREVIEW_CANVAS_ID = 'preview-canvas'
export const CUSTOMILY_CANVAS_WRAPPER_CLASS = 'canvas-wrapper'
export const CUSTOMILY_CANVAS_CONTAINER_CLASS = 'canvas-container'
export const PRODUCT_AVERAGE_REVIEW_SELECTOR = '.jdgm-prev-badge__stars'
export const FORM_ITEM_INVALID_CLASS = 'sib-form-item--invalid'
export const FORM_ITEM_INVALID_SELECTOR = `.${FORM_ITEM_INVALID_CLASS}`
export const STICKY_BUTTON_SHOW_PREVIEW_CONTAINER_CLASS = `sticky-button-show-preview-container`

export const CUSTOMILY_FILE_INPUT_WRAPPER_CLASS = 'customily-file-input'
export const SIB_CUSTOM_UPLOAD_IMAGE_ID_PREFIX = 'sib-custom-upload-image'
export const SIB_POSITION_CUSTOM_PERSONALIZATION_PREFIX =
  'sib-position-custom-personalization'
export const REMOVE_BUTTON_CUSTOMILY_ATTRIBUTE =
  'data-hidden-remove-image-customily'
export const SELECT_IMAGE_BUTTON_CUSTOMILY_SELECTOR =
  'button[class*="customily-download-button"]'
export const REMOVE_IMAGE_BUTTON_CUSTOMILY_SELECTOR =
  'button[class*="cl_remove-image"]'
export const INPUT_FILE_CUSTOMILY_SELECTOR =
  'input:not(.sib-file-select-input)[type="file"]'
export const INPUT_TEXT_CUSTOMILY_SELECTOR = 'input[type="text"]'
export const CUSTOMILY_OPTION_SORT_ID_WRAPPER_SELECTOR =
  'customily_option:not([visually-hidden="true"])[sort-id="option_sort_id"]'
export const CUSTOMILY_OPTION_SORT_ID_WRAPPER_SELECTOR_V2 =
  'customily_option[sort-id="option_sort_id"]'
export const CUSTOMIZATION_UPLOAD_IMAGE_WRAPPER_CLASS =
  'customization-upload-image-wrapper'
export const SIB_FILE_SELECT_WRAPPER_CLASS = 'sib-file-select-wrapper'
export const INPUT_CALENDAR_CUSTOMILY_SELECTOR = `input[name="properties[Calendar]"]`
export const HELP_TEXT_CUSTOMILY_SELECTOR = 'small.help-text'
export const CL_CONTAINER_CUSTOMILY_SELECTOR = 'cl-container'
export const INPUT_CUSTOMILY_SWATCH_SELECTOR = '.customily-swatch input'

export const PRODUCT_LONG_DESCRIPTION_SELECTOR = '.product__description-long'

// Http content type
export const HTTP_CONTENT_TYPE_JSON = 'application/json'
export const HTTP_CONTENT_TYPE_FORM_DATA = 'multipart/form-data'
export const HTTP_CONTENT_TYPE_BLOB = 'application/octet-stream'

// Promotion bar type and priority
export const PROMOTION_BAR_TYPE_DISCOUNT = 'discount'
export const PROMOTION_BAR_TYPE_ANNOUNCEMENT = 'announcement'
export const PROMOTION_BAR_TYPE_FIXED_CAMPAIGN = 'fixedCampaign'
export const PROMOTION_BAR_PRIORITY_HIGHEST = 1
export const PROMOTION_BAR_PRIORITY_HIGH = 3
export const PROMOTION_BAR_PRIORITY_MEDIUM = 5
export const PROMOTION_BAR_PRIORITY_LOW = 7
export const PROMOTION_BAR_PRIORITY_LOWEST = 10

// error
export const REDIS_ECONNREFUSED_ERROR_CODE = -61

// time in  milliseconds
export const ONE_YEAR_IN_MILLISECONDS = 12 * 30 * 24 * 60 * 60 * 1000
export const SIX_MONTH_IN_MILLISECONDS = 6 * 30 * 24 * 60 * 60 * 1000
export const THIRTY_DAYS_IN_MILLISECONDS = 30 * 24 * 60 * 60 * 1000
export const SEVEN_DAYS_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000
export const THREE_DAYS_IN_MILLISECONDS = 3 * 24 * 60 * 60 * 1000
export const ONE_DAY_IN_MILLISECONDS = 1 * 24 * 60 * 60 * 1000
export const SIX_HOURS_IN_MILLISECONDS = 6 * 60 * 60 * 1000

// time in seconds
export const ONE_YEAR_IN_SECONDS = 365 * 24 * 60 * 60
export const ONE_MONTH_IN_SECONDS = 30 * 24 * 60 * 60
export const THIRTY_DAYS_IN_SECONDS = 30 * 24 * 60 * 60
export const ONE_DAY_IN_SECONDS = 1 * 24 * 60 * 60
export const SEVEN_DAY_IN_SECONDS = 7 * 24 * 60 * 60

// settings type
export const SETTING_TYPE_MAIN = 'settings'
export const SETTING_TYPE_POPUP = 'popupSettings'
export const SETTING_TYPE_AB_TEST = 'abTestSetting'
export const SETTING_TYPE_STYLE = 'styleSettings'
export const SETTING_TYPE_SCRIPT = 'scriptSettings'
export const SETTING_TYPE_PRODUCT_PAGE = 'productPageSettings'
export const SETTING_TYPE_PRODUCT_LANDING_PAGE = 'productLandingPageSettings'
export const SETTING_TYPE_CAMPAIGN_DISCOUNT = 'campaignsDiscountSettings'
export const CUSTOMIZE_FIELD_TYPE_TEXT = 'text'
export const CUSTOMIZE_FIELD_TYPE_TEXTAREA = 'textarea'
export const CUSTOMIZE_FIELD_TYPE_SINGLE_IMAGE = 'single_image'
export const CUSTOMIZE_FIELD_TYPE_MULTIPLE_IMAGE = 'multiple_image'
export const CUSTOMIZE_FIELD_NOTE_TYPE_TEXT = 'text'
export const CUSTOMIZE_FIELD_NOTE_TYPE_HTML = 'html'
export const CUSTOMIZE_FIELD_NOTE_TYPE_IMAGE = 'image'
export const CUSTOMIZE_FIELD_TYPE_DROPDOWN = 'dropdown'
export const CUSTOMIZE_FIELD_TYPE_RADIO_GROUP = 'radio_group'
export const CUSTOMIZE_FIELD_TYPE_AUDIO = 'audio'
export const CUSTOMIZE_FIELD_TYPE_SEARCH_SONG = 'search_song'
export const CUSTOMIZE_FIELD_TYPE_AUDIO_OR_SEARCH_SONG = 'audio_or_search_song'
export const CUSTOMIZE_FIELD_COLOR_PICKER = 'color_picker'
export const CUSTOMIZE_FIELD_TYPE_URL = 'url'

// destination rudderstack
export const MIXPANEL_DESTINATION = 'Mixpanel'
export const GOOGLE_ANALYTICS_4_DESTINATION = 'Google Analytics 4'

export const TIKTOK_EXTERNAL_ID_TYPE = 'tiktokExternalId'

// restric product
export const RESTRIC_VN_LOCATION_PRODUCT_TAGS = 'clo1,CLO1,clo2,CLO2'
export const RESTRIC_TMN1_SEGMENT_PRODUCT_TAGS = 'tmm1,TMM1,tmn1,TMN1'

export const TMN1_SEGMENT = 'TMN1'

export const RESPONSE_API_ERROR_MESSAGE =
  'Something went wrong. Please try again later.'

export const DONATION_PREFIX = 'donation'

export const CUSTOMIZE_EACH_PAGE_TITLE = 'Customize Each Page Individually'

export const MADEMINE_PRODUCT_HANDLE_PREFIX = 'mdemn'

// referer param
export const PRODUCT_PAGE_RECENTLY_VIEWED_ITEMS = 'pp_recently-viewed-items'
export const PRODUCT_PAGE_NICHE_ITEMS = 'pp_niche-items'
export const PRODUCT_PAGE_MORE_ITEMS = 'pp_more-items'
export const PRODUCT_PAGE_EXPLORE_BUTTON = 'pp_explore-btn'
export const PRODUCT_PAGE_SEE_MORE_BUTTON = 'pp_see-more-btn'
export const PRODUCT_PAGE_SIMILAR_ITEMS = 'pp_similar-items'
export const PRODUCT_PAGE_NON_NICHE_ITEMS = 'pp_non-niche-items'
export const PRODUCT_PAGE_SAME_DESIGN = 'pp_same-design'

export const HOME_PAGE_CTA_MENU = 'hp_cta-menu'
export const HOME_PAGE_MAIN_SLIDER = 'hp_main-slider'
export const HOME_PAGE_DISCOUNT_BOX = 'hp_discount-box'
export const HOME_PAGE_RECIPIENT_COLLECTION = 'hp_recipient-collection'
export const HOME_PAGE_PERSONALIZE_YOUR_GIFT = 'hp_personalize-your-gift'
export const HOME_PAGE_THEMES = 'hp_themes'
export const HOME_PAGE_OCCASIONS = 'hp_occasions'
export const HOME_PAGE_SHOP_UNIQUE_GIFT = 'hp_shop-unique-gift'
export const HOME_PAGE_PRODUCT_CATEGORIES = 'hp_product-categories'
export const HOME_PAGE_NEW_AND_TRENDING = 'hp_new-and-trending'
export const HOME_PAGE_BEST_SELLING = 'hp_best-selling'
export const HOME_PAGE_MORE_TO_EXPLORE = 'hp_more-to-explore'
export const HOME_PAGE_GET_TO_KNOW_US = 'hp_get-to-know-us'
export const HOME_PAGE_SHOP_FOR_GOOD = 'hp_shop-for-good'
export const HOME_PAGE_DESIGN_YOUR_OWN = 'hp_design-your-own'
export const HOME_PAGE_BANNER = 'hp_banner'
export const HOME_PAGE_SPECIAL_PERKS_FOR_SPECIALS_BANNER =
  'hp_special-perks-for-specials-banner'
export const HOME_PAGE_DOING_GOOD_TEGETHER = 'hp_doing-good-tegether'
export const HOME_PAGE_BRAND_LOGOS = 'hp_brand-logos'
export const HOME_PAGE_SUBSCRIBE_EMAIL_FORM = 'hp_subscribe-email-form'

// camp win setting
export const HIDDEN_PRODUCED_IN_SHIPPED_FROM_CAMP_WIN = 'false'
export const PRODUCT_HANDLE_CAMP_WIN =
  'cool-mum-personalized-mother-all-over-shirt-12980324001scl1tmm1tr04nh02tr01aos1mth7138'
export const NEW_VARIANTS_CAMP_WIN_SIP =
  '43433703440580,43433703506116,43433703538884,43433703571652,43433703604420,43433703637188,43433703669956,43433703702724,43433703735492,43433703768260,43433703801028,43433703833796,43433703866564,43433703899332,43433703932100,43433703964868,43433703997636,43433704030404,43433704063172,43433704095940,43433704161476,43433704194244,43433704227012,43433704259780,43433704292548,43433704325316,43433704358084,43433704390852,43433704423620,43433704456388,43433704489156,43433704521924,43433704554692,43433704587460,43433704652996,43433704685764,43433704718532,43433704784068,43433704816836,43433704849604,43433704915140,43433704947908,43433704980676,43433705013444,43433705046212,43433705078980'
export const NEW_VARIANT_DEFAULT_CAMP_WIN_SIP = '43433703440580'

export const NEW_VARIANTS_CAMP_WIN_SIB =
  '45127360741550,45127360774318,45127360807086,45127360839854,45127360872622,45127360905390,45127360938158,45127360970926,45127361003694,45127361036462,45127361069230,45127361101998,45127361134766,45127361167534,45127361200302,45127361233070,45127361265838,45127361298606,45127361331374,45127361364142,45127361396910,45127361429678,45127361462446,45127361495214,45127361527982,45127361560750,45127361593518,45127361626286,45127361659054,45127361691822,45127361724590,45127361757358,45127361790126,45127361822894,45127361855662,45127361888430,45127361921198,45127361953966,45127361986734,45127362019502,45127362052270,45127362085038,45127362117806,45127362150574,45127362183342,45127362216110'
export const NEW_VARIANT_DEFAULT_CAMP_WIN_SIB = '45127360741550'

// Judgeme
export const JUDGEME_SHOPIFY_DOMAIN = import.meta.env
  .VITE_SHOPIFY_DOMAIN as string
export const JUDGEME_PUBLIC_TOKEN = import.meta.env
  .VITE_JUDGEME_PUBLIC_TOKEN as string

export const PRODUCT_HANDLES_NO_APPLY_PACK_DISCOUNT =
  'merry-grinchmas-personalized-family-ornament-16030924001scl1tmn1tr04li10th12onm1fml5489,no-job-is-too-big-no-pup-is-too-small-personalized-kid-ornament-12511024001scl1tmn1tr04li10ha12onm1kid5186'

export const PRODUCT_HANDLE_PRICE_TEST =
  'merry-christmas-personalized-family-table-runner-10611024001scl1tmn1tr04th07th12tbr1fml5497'

// Theme
export const PROJECT_THEME = import.meta.env.VITE_PROJECT_THEME as string
