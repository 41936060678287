import { defineStore } from 'pinia'
import {
  Image,
  NullOrType,
  Product,
  ProductDescription,
  ProductOptionType,
  ProductSameDesign,
  ProductShipping,
  ProductVariant,
  Recommendation,
} from '@/types'
import {
  cloneDeep,
  getSameElementOfTwoArray,
  OPTION_TYPE_SHIPPED_FROM,
  OPTION_TYPE_STYLE,
  OPTION_TYPE_UNKNOW,
  PRODUCT_SOURCE_FORMAT_MP4,
  SIX_HOURS_IN_MILLISECONDS,
  PRODUCT_TAG_UI_LANDING,
} from '@/utils'
import {
  extractProductSKUFromHandle,
  extractProductNicheFromHandle,
  getOptionType,
  extractProductCodeFromHandle,
  isTMProduct as checkIsTMProduct,
  isUILandingProduct as checkUILandingProduct,
  getTruncatedVariantSKU,
  getTruncatedVariantSKUWithProductHandlle,
} from '@/utils/product'
import Logger from '@/services/log'
import Provider from '@/provider'
import useSettingStore from '../setting'
import CacheService, { getJSONfromCacheResponse } from '@/services/cache'
import { JudgemeCustomFormQuestion } from '@/provider/type'
import Analytics from '@/services/analytics'
import { timeEnd, timeStart } from '@/utils/timing'

export interface ProductState {
  product: NullOrType<Product>
  isProductNotFound: boolean
  isProductOnlySellInUSA: boolean
  isSelectedVariantOnlySellInUSA: boolean
  isSelectedVariantOptionTypeSellInAllLocation: boolean
  didUserChooseVariant: boolean
  products: NullOrType<Product[]>
  selectedVariant: NullOrType<ProductVariant>
  productDescription: NullOrType<ProductDescription>
  productShipping: NullOrType<ProductShipping>
  shippingFromChina: NullOrType<ProductShipping>
  shippingFromUS: NullOrType<ProductShipping>
  productRecommendation: NullOrType<{
    moreItems: NullOrType<Product[]>
    similarItems: NullOrType<Product[]>
    nonNicheItems: NullOrType<Product[]>
    nicheItems: NullOrType<Product[]>
    fatherWeed: NullOrType<Product[]>
    motherWeed: NullOrType<Product[]>
    coupleWeed: NullOrType<Product[]>
  }>
  productRecommendationShopify: NullOrType<Product[]>
  productPreviewImage: NullOrType<string>
  hasPreview: boolean
  judgemeReviewCustomQuestions: JudgemeCustomFormQuestion[]
  numberOfPersonalizedProductVisited: number
  isProductBlockedByUserSegment: boolean
  productSameDesign: NullOrType<ProductSameDesign[]>
  productsExtra: {
    productHanlde: string
    productVariantIdSelected: string
  }[]
  isProductUINewVersion: boolean
  similarNicheCollectionHandle: string
  productComboAddByATC: boolean
  productComboSelected: any
  redirectProductHandle: string
  currentWordCustomPhotoWordProduct: any
}

const useProductStore = defineStore('product', {
  state: (): ProductState => ({
    product: null,
    isProductNotFound: false,
    isProductOnlySellInUSA: false,
    isSelectedVariantOnlySellInUSA: false,
    isSelectedVariantOptionTypeSellInAllLocation: false,
    didUserChooseVariant: false,
    selectedVariant: null,
    productDescription: null,
    products: null,
    productShipping: null,
    shippingFromChina: null,
    shippingFromUS: null,
    productRecommendation: null,
    productRecommendationShopify: null,
    productPreviewImage: null,
    hasPreview: false,
    judgemeReviewCustomQuestions: [],
    numberOfPersonalizedProductVisited: 0,
    isProductBlockedByUserSegment: false,
    productSameDesign: null,
    productsExtra: [],
    isProductUINewVersion: false,
    similarNicheCollectionHandle: '',
    productComboAddByATC: false,
    productComboSelected: null,
    redirectProductHandle: null,
    currentWordCustomPhotoWordProduct: {},
  }),
  getters: {
    selectedVariantStyleOptionValue: (state: ProductState) => {
      if (!state.selectedVariant) return ''
      const stypeOption = state.selectedVariant.selectedOptions?.find(
        (option) => getOptionType(option.name) === OPTION_TYPE_STYLE
      )

      return stypeOption?.value || ''
    },
    shouldLoadProductDescriptionAndShippingByVariantType(state: ProductState) {
      if (!state.product) return false
      const settingStore = useSettingStore()
      const tags =
        settingStore.shop
          ?.productTagsToLoadDescriptionAndShippingByVariantType || []
      const productTags = state.product.tags || []
      const sameElements = getSameElementOfTwoArray(tags, productTags)
      return sameElements.length > 0
    },
    selectedShippedOption(state: ProductState) {
      if (!state.selectedVariant) return ''
      const selectedShippedOption = state.selectedVariant.selectedOptions?.find(
        (selectedOption) => selectedOption.name === OPTION_TYPE_SHIPPED_FROM
      )
      if (!selectedShippedOption) return ''
      return selectedShippedOption.value
    },
    productSKU(state) {
      if (!state.product) return ''
      return extractProductSKUFromHandle(state.product.handle)
    },
    productCode(state) {
      if (!state.product) return ''
      return extractProductCodeFromHandle(state.product.handle)
    },
    productNiche(state) {
      if (!state.product) return ''
      return extractProductNicheFromHandle(state.product.handle)
    },
    isTMProduct(state) {
      if (!state.product) return false
      const settingStore = useSettingStore()
      return checkIsTMProduct(state.product, settingStore.tmProductTags)
    },
    productShippingTime(state) {
      const settingStore = useSettingStore()
      return {
        maximumShipping:
          state.productShipping?.maximum_shipping ||
          settingStore.shop?.productShippingDefault?.maximumShipping ||
          0,
        minimumShipping:
          state.productShipping?.minimum_shipping ||
          settingStore.shop?.productShippingDefault?.minimumShipping ||
          0,
        processing:
          state.productShipping?.processing ||
          settingStore.shop?.productShippingDefault?.processing ||
          0,
      }
    },
    productExpressShippingTime(state) {
      const settingStore = useSettingStore()
      return {
        maximumShipping:
          state.productShipping?.us_max_express ||
          settingStore.shop?.productShippingDefault?.maximumShipping ||
          0,
        minimumShipping:
          state.productShipping?.us_min_express ||
          settingStore.shop?.productShippingDefault?.minimumShipping ||
          0,
        processing:
          state.productShipping?.us_processing_express ||
          settingStore.shop?.productShippingDefault?.processing ||
          0,
      }
    },
    isUILandingProduct(state) {
      if (!state.product) return false
      return checkUILandingProduct(state.product, PRODUCT_TAG_UI_LANDING)
    },
  },
  actions: {
    // Product zone
    async getProductById({ id }: { id: string }) {
      try {
        const provider = await Provider.getInstance()
        const product = await provider.getProductById(id)
        this.product = fillUpProductProperties(product)
      } catch (error: any) {
        Analytics.error(error)
        this.product = null
        Logger.error('Error on getProductById', { error, id })
      }
    },

    async getProductByHandle({
      handle,
      isForceLoadNewData,
    }: {
      handle: string
      isForceLoadNewData?: boolean
    }) {
      timeStart('Get product by handle ' + handle)
      try {
        let product = null
        const cacheKey = `product_getProductByHandle_${handle}`
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const productFromCache = getJSONfromCacheResponse(cacheResponse)

          // get product from cache first
          if (productFromCache) {
            product = productFromCache
          }
        }

        // if can't get product from cache, get from provider
        if (!product) {
          const provider = await Provider.getInstance()
          product = await provider.getProductByHandle(handle)

          product = fillUpProductProperties(product)

          if (product) {
            CacheService.instance?.set(cacheKey, product)
            product.__cacheKey = cacheKey
          } else {
            CacheService.instance?.delete(cacheKey)
          }
        }

        timeEnd('Get product by handle ' + handle)
        return product
      } catch (error: any) {
        Logger.error('Error on getProductByHandle', { error, handle })
        Analytics.error(error)
        throw error
      }
    },

    // get and set product to store
    async fetchProduct({
      handle,
      isForceLoadNewData,
    }: {
      handle: string
      isForceLoadNewData?: boolean
    }) {
      this.product = null
      this.isProductNotFound = false
      const product = await this.getProductByHandle({
        handle,
        isForceLoadNewData,
      })
      this.product = product
      return product
    },

    async getProductDescription({
      code,
      type,
      tag,
      isForceLoadNewData,
    }: {
      code: string
      type?: string
      tag?: string
      isForceLoadNewData?: boolean
    }) {
      try {
        const cacheKey = `product_getProductDescription_${code}_${type}_${tag}`
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const descriptionFromCache = getJSONfromCacheResponse(cacheResponse)

          if (descriptionFromCache) {
            this.productDescription = descriptionFromCache
            return this.productDescription
          }
        }

        const provider = await Provider.getInstance()
        const description = await provider.getProductDescription({
          code,
          type,
          tag,
        })
        this.productDescription = description
        CacheService.instance?.set(
          cacheKey,
          description,
          SIX_HOURS_IN_MILLISECONDS
        )
        return this.productDescription
      } catch (error: any) {
        Logger.error('Error on getProductDescription', {
          error,
          payload: { code, type, tag },
        })
        Analytics.error(error)
        return null
      }
    },

    async getProductShipping({
      product_type,
      delivery_country_code,
      isForceLoadNewData,
    }: {
      product_type: string
      delivery_country_code?: string
      isForceLoadNewData?: boolean
    }) {
      try {
        const cacheKey = `product_getProductShipping_${product_type}_${
          delivery_country_code || ''
        }`
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const shippingFromCache = getJSONfromCacheResponse(cacheResponse)

          if (shippingFromCache) {
            this.productShipping = shippingFromCache
            return this.productShipping
          }
        }

        const provider = await Provider.getInstance()
        const shipping = await provider.getProductShipping({
          product_type,
          delivery_country_code,
        })
        this.productShipping = shipping
        CacheService.instance?.set(
          cacheKey,
          shipping,
          SIX_HOURS_IN_MILLISECONDS
        )
        return this.productShipping
      } catch (error: any) {
        Logger.error('Error on getProductShipping', {
          error,
          payload: { product_type, delivery_country_code },
        })
        Analytics.error(error)
        return null
      }
    },

    async getProductShippingBySkuPrefix({
      sku_prefix,
      full_sku,
      vendor,
      delivery_country_code,
      isForceLoadNewData,
    }: {
      sku_prefix: string
      full_sku: string
      vendor: string
      delivery_country_code?: string
      isForceLoadNewData?: boolean
    }) {
      try {
        const cacheKey = `product_getProductShippingbySkuPrefix_${sku_prefix}_${full_sku}_${vendor}_${
          delivery_country_code || ''
        }`
        let productShipping
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const shippingFromCache = getJSONfromCacheResponse(cacheResponse)

          if (shippingFromCache) {
            productShipping = shippingFromCache
            return productShipping
          }
        }

        const provider = await Provider.getInstance()
        const shipping = await provider.getProductShippingBySku({
          sku_prefix,
          full_sku,
          vendor,
          delivery_country_code,
        })
        productShipping = shipping
        CacheService.instance?.set(
          cacheKey,
          shipping,
          SIX_HOURS_IN_MILLISECONDS
        )
        return productShipping
      } catch (error: any) {
        Logger.error('Error on getProductShippingBySkuPrefix', {
          error,
          payload: { sku_prefix, full_sku, vendor, delivery_country_code },
        })
        Analytics.error(error)
        return null
      }
    },

    // get and set product to store
    async fetchProductShippingBySkuPrefix({
      sku_prefix,
      full_sku,
      vendor,
      delivery_country_code,
      isForceLoadNewData,
    }: {
      sku_prefix: string
      full_sku: string
      vendor: string
      delivery_country_code?: string
      isForceLoadNewData?: boolean
    }) {
      const productShipping = await this.getProductShippingBySkuPrefix({
        sku_prefix,
        full_sku,
        vendor,
        delivery_country_code,
        isForceLoadNewData,
      })
      this.productShipping = productShipping
      return productShipping
    },

    async getProductRecommendation(payload: Recommendation) {
      try {
        const cacheKey = `product_getProductRecommendation_${JSON.stringify(
          payload
        )}`
        let productRecommendation

        const cacheResponse = await CacheService.instance?.get(cacheKey)
        const productRecommendationFromCache =
          getJSONfromCacheResponse(cacheResponse)

        if (productRecommendationFromCache) {
          productRecommendation = productRecommendationFromCache
        }

        if (!productRecommendation) {
          const provider = await Provider.getInstance()
          productRecommendation = await provider.getProductRecommendation(
            payload
          )

          if (productRecommendation) {
            CacheService.instance?.set(cacheKey, productRecommendation)
          } else {
            CacheService.instance?.delete(cacheKey)
          }
        }

        productRecommendation = {
          moreItems: productRecommendation?.moreItems.map(
            (product: Product) => fillUpProductProperties(product)!
          ),
          similarItems: productRecommendation?.similarItems.map(
            (product: Product) => fillUpProductProperties(product)!
          ),
          nonNicheItems: productRecommendation?.nonNicheItems.map(
            (product: Product) => fillUpProductProperties(product)!
          ),
          nicheItems: productRecommendation?.nicheItems.map(
            (product: Product) => fillUpProductProperties(product)!
          ),
          fatherWeed: productRecommendation?.fatherWeed.map(
            (product: Product) => fillUpProductProperties(product)!
          ),
          motherWeed: productRecommendation?.motherWeed.map(
            (product: Product) => fillUpProductProperties(product)!
          ),
          coupleWeed: productRecommendation?.coupleWeed.map(
            (product: Product) => fillUpProductProperties(product)!
          ),
        }

        this.productRecommendation = productRecommendation
        return this.productRecommendation
      } catch (error: any) {
        Logger.error('Error on getProductRecommendation', {
          error,
          payload,
        })
        Analytics.error(error)
        return null
      }
    },

    async getProductRecentlyViewed(payload: { recently_view: string[] }) {
      try {
        const provider = await Provider.getInstance()
        let recentlyViewedItems = await provider.getProductRecentlyViewedItems(
          payload
        )
        recentlyViewedItems = recentlyViewedItems.map(
          (product: Product) => fillUpProductProperties(product)!
        )
        return recentlyViewedItems
      } catch (error: any) {
        Logger.error('Error on getProductRecentlyViewed', {
          error,
          payload,
        })
        Analytics.error(error)
        return null
      }
    },

    async getProductSameDesign({
      handle,
      store,
      isForceLoadNewData,
    }: {
      handle: string
      store: string
      isForceLoadNewData?: boolean
    }) {
      try {
        const cacheKey = `product_getProductSameDesign_${handle}_${store}`
        let productSameDesign = null
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const productSameDesignFromCache =
            getJSONfromCacheResponse(cacheResponse)

          if (productSameDesignFromCache) {
            productSameDesign = productSameDesignFromCache
            this.productSameDesign = productSameDesign
            return productSameDesign
          }
        }
        const provider = await Provider.getInstance()
        productSameDesign = await provider.getProductSameDesign(handle, store)

        if (productSameDesign?.length) {
          CacheService.instance?.set(cacheKey, productSameDesign)
        }
        this.productSameDesign = productSameDesign

        return this.productSameDesign
      } catch (error: any) {
        Logger.error('Error on getProductSameDesign', {
          error,
          handle,
          store,
        })
        Analytics.error(error)
        return null
      }
    },

    async getProductRecommendationsShopify({
      id,
      isForceLoadNewData,
    }: {
      id: string
      isForceLoadNewData?: boolean
    }) {
      try {
        const cacheKey = `product_getProductRecommendationsShopify_${id}`
        let products = null
        if (!isForceLoadNewData) {
          const cacheResponse = await CacheService.instance?.get(cacheKey)
          const productRecommendationFromCache =
            getJSONfromCacheResponse(cacheResponse)

          if (productRecommendationFromCache) {
            products = productRecommendationFromCache

            this.productRecommendationShopify = products?.map((product) =>
              fillUpProductProperties(product)
            )
            return this.productRecommendationShopify
          }
        }

        const provider = await Provider.getInstance()
        products = await provider.getProductRecommendations(id)

        if (products?.length) {
          products = products.map((product) => ({
            ...product,
            images: cloneDeep(product.images).reverse(),
          }))

          CacheService.instance?.set(cacheKey, products)
        }

        this.productRecommendationShopify = products?.map((product) =>
          fillUpProductProperties(product)
        )
        return this.productRecommendationShopify
      } catch (error: any) {
        Analytics.error(error)
        Logger.error('Error on getProductRecommendations', { error })
      }
    },
  },
})

export default useProductStore

export function fillUpProductProperties(
  product: NullOrType<Product>
): NullOrType<Product> {
  if (!product) return null

  // fill up product featured image and listing image
  if (product.images && product.images.length > 0) {
    if (!product.featuredImage) {
      product.featuredImage = cloneDeep(product.images[0])
    }
    if (!product.listingImage) {
      product.listingImage = cloneDeep(
        product.images[product.images.length - 1] as Image
      )
      product.listingImage.width =
        product.images[product.images.length - 1].width || 1024
      product.listingImage.height =
        product.images[product.images.length - 1].height || 1024
      product.listingImage.sources = [
        {
          size: 160,
        },
        {
          size: 265,
          media: 361,
        },
        {
          size: 300,
          media: 576,
        },
        {
          size: 265,
          media: 992,
        },
        {
          size: 300,
          media: 1400,
        },
      ]
    }
    product.images = product.images.map((image) => {
      image.width = image?.width || 1024
      image.height = image?.height || 1024
      image.sources = [
        {
          size: 332, // inital size for all screen
        },
        {
          size: 470,
          media: 361,
        },
        {
          size: 630,
          media: 500,
        },
        {
          size: 440,
          media: 768,
        },
        {
          size: 366,
          media: 992,
        },
        {
          media: 1200, // use natural size
        },
      ]
      return image
    })
  }

  if (product.media?.length) {
    product.media = product.media.map((item) => {
      const index =
        product.images?.findIndex(
          (image) => image.src == item.previewImage.url
        ) ?? -1

      const imageMedia = {
        id: item.previewImage.id,
        alt: item.previewImage.altText || product.title,
        src: item.previewImage.url,
        width: 1024,
        height: 1024,
        sources: [
          {
            size: 332, // inital size for all screen
          },
          {
            size: 470,
            media: 361,
          },
          {
            size: 630,
            media: 500,
          },
          {
            size: 440,
            media: 768,
          },
          {
            size: 366,
            media: 992,
          },
          {
            media: 1200, // use natural size
          },
        ],
      }

      const productSourceMp4 = item.sources?.length
        ? item.sources.filter(
            (source) => source.format === PRODUCT_SOURCE_FORMAT_MP4
          )
        : []

      const productVideo = productSourceMp4?.length
        ? productSourceMp4.find((source) => source.url.includes('HD-720p')) ||
          productSourceMp4[0]
        : null

      const media = {
        mediaContentType: item.mediaContentType,
        video: productVideo || null,
        image: index > -1 ? product.images[index] : imageMedia,
      }

      return media
    })
  }

  // fill up product options
  const optionNameTypeMapping: { [key: string]: ProductOptionType } = {}
  if (product.options && product.options.length > 0) {
    product.options = product.options.map((option) => {
      const type = getOptionType(option.name || '')
      if (option.name) {
        optionNameTypeMapping[option.name || ''] = type
      }
      return { ...option, type }
    })
  }

  // fill up product price and variant option type
  if (product.variants && product.variants.length > 0) {
    const variant = product.variants[0]
    if (variant) {
      if (!product.price) {
        product.price = variant.price || 0
      }
      if (!product.compareAtPrice) {
        product.compareAtPrice = variant.compareAtPrice || 0
      }
    }
    product.variants = product.variants.map((variant) => {
      if (variant.selectedOptions) {
        variant.selectedOptionsValue = variant.selectedOptions
          .map((option) => option.value)
          .join('')
      }
      variant.selectedOptions = variant.selectedOptions?.map((option) => {
        const type = optionNameTypeMapping[option.name] || OPTION_TYPE_UNKNOW
        return { ...option, type }
      })

      variant.skuPrefix = variant.sku?.split('-')[0] || ''
      variant.fullSku = variant.sku || ''

      const skuFromHandle = extractProductSKUFromHandle(product.handle)
      const settingStore = useSettingStore()
      const hasProductHandle =
        settingStore.shop?.googleProductSKUsFromHandle
          ?.split(',')
          .includes(skuFromHandle) || false
      // gg ads only allow sku with less that 50 chars
      variant.sku = hasProductHandle
        ? getTruncatedVariantSKU(variant.sku)
        : getTruncatedVariantSKUWithProductHandlle(variant.sku, product.handle)
      return variant
    })
  }

  return product
}
